import { createApp } from 'vue'
//引入ElementPlus
// import {ElButton,ElForm,ElFormItem,ElInput} from 'element-plus'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
// const components = [ElButton,ElForm,ElFormItem,ElInput]
import * as Icons from '@element-plus/icons-vue'
import App from './App.vue'
import router from './router'
import axios from 'axios';
import {ElMessage} from 'element-plus'
import { createPinia } from 'pinia' //引入pinia
// import {useMutitabsStore} from "./store/mutitabs";

// const mutitabsstore = useMutitabsStore()

const app = createApp(App)
//请求头中携带登录信息
axios.interceptors.request.use(
    config => {
        var username = localStorage.getItem('username');
        var authorization = localStorage.getItem('authorization');
        // 若 localStorage 中含有这两个字段，则添加入请求头
        if (username && authorization) {
            config.headers.authorization = authorization;
            config.headers.username = username;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

function logout(){
    // console.log(mutitabsstore.userName )
    localStorage.removeItem("username")
    localStorage.removeItem("authorization")
    localStorage.removeItem("userbasedata")
    // mutitabsstore.logout()
    router.push({path: "/login"})
  }
axios.interceptors.response.use(
    response => {
        if (response.data.errno) {
            // logout()
                ElMessage({
                    message: response.data.msg,
                    duration: 1500,
                    type: 'error'
                })
                setTimeout(() => {
                    localStorage.removeItem("username")
                    localStorage.removeItem("authorization")
                    localStorage.removeItem("userbasedata")
                    location. reload()
                    router.push({path: "/login"})
                    // logout()
                },2000)
                // router.replace({
                //     path: '/login',
                // }) 
            }
            //  else if (response.code === 500) {
            //     Vue.prototype.$message.error({
            //         message: response.data.msg || '服务器忙碌, 请稍后尝试',
            //         duration: 1500
            //     })
        return response;
    },
    error => {
        return Promise.reject(error.response.data)   // 返回接口返回的错误信息
    });


Object.keys(Icons).forEach((key) => {
    app.component(key, Icons[key]);
  });

// app.use(ElButton)
app.use(ElementPlus,{
    size: 'default',  // 默认控件尺寸default(其他large、small)
    zIndex: 3000,  // 弹出组件的zIndex
    locale: zhCn,
  })
// app.use(ElForm)
// app.use(ElFormItem)
// app.use(ElInput)
app.use(router)
app.use(createPinia())
app.mount('#app')



