import { createRouter, createWebHistory ,createWebHashHistory } from 'vue-router'
// import {useMutitabsStore} from "@/store/mutitabs";
// 进度条
// import NProgress from 'nprogress'
// import 'nprogress/nprogress.css'
// import '../assets/css/nprogress.scss'//自定义样式
// 简单配置
// NProgress.inc(0.4)
// NProgress.configure({ easing: 'ease', speed: 50, showSpinner: true })

const routes = [
  {
    path: '/',
    name: 'root',
    component: () => import('../views/index.vue'),
    hidden: true,
    meta: {
      requireAuth: false,
      index: '/',
    },
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import('../views/login.vue'),
        hidden: true,
        meta: {
          requireAuth: false,
          index: '/login',
        }
      },
      {
        path: '/reset',
        name: 'reset',
        component: () => import('../views/resetpassword.vue'),
        hidden: true,
        meta: {
          requireAuth: false,
          index: '/reset',
        }
      },
      {
        path: '/infomanagement',
        name: 'infomanagement',
        component: () => import('../views/infomanagement.vue'),
        hidden: true,
        meta: {
          requireAuth: true,
          index: '/login',
        },
        children:[
          {
            path: '/infomanagement/assettree',
            name: 'assettree',
            component: () => import('../views/assettree.vue'),
            hidden: true,
            meta: {
              requireAuth: true,
              index: '/assettree',
            }
          },
          {
            path: '/infomanagement/measurepoint',
            name: 'measurepoint',
            component: () => import('../views/measurepoint.vue'),
            hidden: true,
            meta: {
              requireAuth: true,
              index: '/measurepoint',
            }
          },
          {
            path: '/infomanagement/historydata',
            name: 'historydata',
            component: () => import('../views/historydata.vue'),
            hidden: true,
            meta: {
              requireAuth: true,
              index: '/historydata',
            }
          },

        ]
      },
      {
        path: '/diag',
        name: 'diag',
        component: () => import('../views/diag.vue'),
        hidden: true,
        meta: {
          requireAuth: false,
          index: '/diag',
        }
      },
      {
        path: '/register',
        name: 'register',
        component: () => import('../views/register.vue'),
        hidden: true,
        meta: {
          requireAuth: false,
          index: '/register',
        }
      },
      {    path: '/monitor',
            name: 'monitor',
            component: () => import('../views/monitor.vue'),
            hidden: true,
            meta: {
              requireAuth: false,
              index: '/monitor',},
            children:[
              {
                path: '/monitor/point',
                name: 'pointview',
                component: () => import('../views/pointview.vue'),
                hidden: true,
                meta: {
                  requireAuth: false,
                  index: '/point',
                }
              },
              {
                path: '/monitor/static',
                name: 'static',
                component: () => import('../views/static.vue'),
                hidden: true,
                meta: {
                  requireAuth: false,
                  index: '/static',
                }
              }
            ]},
            {    path: '/monitoring',
            name: 'monitoring',
            component: () => import('../views/monitoring.vue'),
            hidden: true,
            meta: {
              requireAuth: true,
              index: '/monitoring',},
            children:[
              {
                path: '/monitoring/staticview',
                name: 'staticview',
                component: () => import('../views/staticview.vue'),
                hidden: true,
                meta: {
                  requireAuth: true,
                  index: '/staticview',
                }
              },
              {
                path: '/monitoring/point',
                name: 'point',
                component: () => import('../views/point.vue'),
                hidden: true,
                meta: {
                  requireAuth: true,
                  index: '/point',
                }
              }
            ]},
                    {
          path: '/about',
          name: 'about',
          component: () => import('../views/about.vue'),
          hidden: true,
          meta: {
            requireAuth: false,
            index: '/about',
          }},

  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about.vue'),
    hidden: true,
    meta: {
      requireAuth: false,
      index: '/about',
    }}],
  }]

  const router = createRouter({
    //history模式
    // history: createWebHistory(process.env.BASE_URL),
    //hash模式
    history: createWebHashHistory(),
    routes: routes
  })

router.beforeEach((to, from, next) => {
  // console.log(to);
  // console.log(from);
  if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
    if(localStorage.getItem('authorization')){ //判断本地是否存在access_token
      next();
    }else {next({path: 'login',
    query: {redirect:to.fullPath}
   })
    }
  }
  // else {
  //   next();
  // }
  /*如果本地 存在 token 则 不允许直接跳转到 登录页面*/
  else if(to.fullPath == "/login"){
    // console.log('to',to)
    if(localStorage.getItem('authorization')){
      next({path:'/'
      });
    }
    else {next()}
  }
  else {next()}
});





  export default router